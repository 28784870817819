import React from 'react'
import { connect } from 'react-redux'

import { Layout,   FlexCol, Parallax, DelayedLink,  } from '@components/layout'

import { DoubleCTASection, Breadcrumbs, ResizedImage as Image } from '@components/shared'
import { AltAddToCart, PricingItem } from '../../components.refactored/standard'
import { cartActions } from '../../state'

import {
    page__wrapper, page__header, page__content
} from './styles/page.module.scss'

import{
    support__quote,
    support__title,
    support__item__payment,
} from './styles/support.module.scss'

const Support = ({pageContext, addToCart, store }) => {
    const { page, products } = pageContext
    
    return (
        <Layout
            animation={false}
            seo={pageContext.page.seo}
            siteMetaData={pageContext.page.siteMetaData}
        >
        <h3>do refactoru</h3>
        {/*
                    <Parallax height={300}
                        image={page.featuredImage}
                    >
                        <h4>MOŻESZ WESPRZEĆ INICJATYWĘ</h4>
                    </Parallax>
                    <div className={page__wrapper}>
                        <div className={page__content}>
                        <Breadcrumbs
                                elements={[
                                    {
                                        label: page.title,
                                        url: `/${page.slug}/`,
                                    },
                                ]}
                            />
                            {/* <h5>{JSON.stringify(items)}</h5> 
                            <h5>
                            Dla Ciebie każde zrobione zadanie ze zbioru to okazja do poznania nowych rzeczy, 
                            albo zastosowania funkcjonlaności której jeszcze nie maiłes okazji zastosować. 
                            </h5>
                            <h5>
                            Dla mnie każdy kupiony zbiór to nagroda za aktywność w polskiej społeczności programistów oraz potwierdzenie, 
                            że w różnych etapach rozwoju developera są potrzebne zadania, które stanowią wyzwanie   
                            </h5>
                            <h5 className={support__quote}>
                            Dobrane przeze mnie zadania są komplementarne i pozwoliły Ci na przetrenowanie 130% wyzwań,
                            z którymi będziesz się mierzyć w pracy programisty Frontendowego
                            </h5>
                            {products.map((product, k) => {
                                return (
                                    <PricingItem 
                                    addToCart={addToCart}
                                    product={product} 
                                    cart={store.items}
                                    key={k}/>
                                )
                            })}
                            <div className={support__item__payment}>
                                <DelayedLink to={'/checkout'} children={'Do płatności'}/>
                            </div>
                            <div className={support__title}>
                            <h5 >
                            Możesz wspomóc też moją inicjatywę bezpłatnie
                            </h5>
                            </div>
                        </div>
                    </div>
                <DoubleCTASection 
            label1={"Zostaw gwiazdkę na repo"}
            label2={"Polajkuj fanpage"}/>
        */}
        </Layout>
    )
}


const mapStateToProps = (state) => ({ store: state.Cart })

const mapActionToDispatch = { ...cartActions }

export default connect(mapStateToProps, mapActionToDispatch)(Support)